
import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import { IIndicators } from '@/modules/common/components/ci-table';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type RatesService from '../../rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../../rates-analysis-filters.service';

export interface RatesTableTooltipData {
    hotelId: number | string;
    previewValues: { [k: string]: any };
    indicators?: IIndicators;
    outOfRange?: boolean;
}

@Component
export default class RatesTableTooltip extends DayTooltipTemplate {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    @Prop({ type: Object, required: true })
    options!: RatesTableTooltipData;

    protected reversedChangingSide = true;

    get tooltipWidth() {
        return this.haveIndicators
            ? '350px'
            : '400px';
    }

    get date() {
        const { day } = this;
        const { month, year } = this.documentFiltersService;
        const d = new Date(year, month, day);
        return moment(d).format('ddd D/MM');
    }

    get isActive() {
        const haveData = false
            || this.havePreview
            || this.haveIndicators;

        return haveData && !!this.focusElement;
    }

    get isAnalysisMode() {
        return this.$route.name?.includes('.analysis') ?? false;
    }

    get havePreview() {
        return !!Object
            .values(this.previewValues)
            .filter(item => item?.value || item?.component).length;
    }

    get haveIndicators() {
        return Object
            .values(this.indicators || {})
            .some(ind => !!ind);
    }

    get outOfRange() {
        return this.options.outOfRange;
    }

    get hotelName() {
        const { hotelId } = this.options;
        return this.hotelsService.hotelNames[hotelId];
    }

    get previewValues() {
        return this.options.previewValues;
    }

    get indicators() {
        return this.options.indicators;
    }

    get roomNames() {
        const names = {} as Record<string, string[]>;
        if (!this.isAnalysisMode) {
            return null;
        }

        [...this.ratesAnalysisService.data, this.ratesService.data]
            .forEach((doc, docIndex) => {
                const isMainDoc = doc === this.ratesService.data;
                const service = isMainDoc ? this.ratesService : this.ratesAnalysisService;
                const args: Parameters<RatesAnalysisService['isSoldOut']> = [this.day, +this.options.hotelId, undefined, docIndex];

                const isSoldOut = service.isSoldOut(...args);

                if (isSoldOut) {
                    return;
                }

                const roomName = service.getRoom(...args)?.roomName || '';

                const { comparisonValues, mainCompareTitle } = this.ratesAnalysisFiltersService;
                const value = !isMainDoc
                    ? comparisonValues[docIndex]?.name
                    : mainCompareTitle;

                if (!roomName || !value) {
                    return;
                }

                names[roomName] = names[roomName] || [];
                names[roomName].push(String(value));
            });

        if (!Object.keys(names).length) {
            return null;
        }

        return names;
    }

    get roomName() {
        const isSoldOut = this.ratesService
            .isSoldOut(this.day, this.options.hotelId);

        if (this.isAnalysisMode || isSoldOut) return '';

        const room = this.ratesService
            .getRoom(this.day, this.options.hotelId);

        if (!room) return '';

        return room.roomName;
    }
}
